import { type FC } from "react";
import TournamentCard from "../cards/tournament/tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import Loader from "components/loaders/default";
import { type BackendModels } from "@double-bagel/endpoints/models/@types/models";
import { TournamentType } from "@double-bagel/endpoints/models/@types/tournament";
import TournamentCardChallenge from "@double-bagel/components/cards/tournament/tournament-challenge/tournament";

interface TournamentCollectionProps {
  tournaments: Array<ClientModel<BackendModels.Tournament>>;
  isFetching?: boolean;
}
const TournamentsCollection: FC<TournamentCollectionProps> = ({ tournaments, isFetching }) => {
  return (
    <div className="relative grid min-h-[42px] w-[100%] max-w-[100%] grid-flow-row gap-6 tb:gap-8 dk:gap-10">
      {isFetching && <Loader />}
      {!isFetching &&
        tournaments?.map((item) => {
          if (item.type === TournamentType.CHALLENGE) {
            return <TournamentCardChallenge key={item.id.toString()} tournament={item} />;
          }
          return <TournamentCard key={item.id.toString()} tournament={item} />;
        })}
    </div>
  );
};

export default TournamentsCollection;
