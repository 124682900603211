import { useEffect, useState, type FC } from "react";
import SubmitOpponentRateModal from "./submit-opponent-rate";
import SubmitScoreModal from "./submit-score-modal";
import { type BackendModels } from "@double-bagel/endpoints/models/@types/models";

type MatchReportModalsControllerProps = {
  tournamentId: BackendModels.ID;
  matchTeamId: BackendModels.ID;
  specificModal?: "score" | "rate";
  showModal: boolean;
  onClose: () => void;
};

const MatchReportModalsController: FC<MatchReportModalsControllerProps> = ({
  tournamentId,
  matchTeamId,
  specificModal,
  showModal,
  onClose,
}) => {
  const [isSubmitResultsModalOpened, setIsSubmitResultsModalOpened] = useState<boolean>();
  const [isRateOpponentModalOpened, setIsRateOpponentModalOpened] = useState<boolean>();

  useEffect(() => {
    if (!specificModal) {
      setIsSubmitResultsModalOpened(true);
      setIsRateOpponentModalOpened(false);
    }
    if (specificModal === "score") {
      setIsSubmitResultsModalOpened(true);
      setIsRateOpponentModalOpened(false);
    }
    if (specificModal === "rate") {
      setIsSubmitResultsModalOpened(false);
      setIsRateOpponentModalOpened(true);
    }
  }, [specificModal, showModal]);
  useEffect(() => {
    if (!isSubmitResultsModalOpened && !isRateOpponentModalOpened) {
      onClose();
    }
  }, [isSubmitResultsModalOpened, isRateOpponentModalOpened]);
  return (
    <>
      <SubmitScoreModal
        matchTeamId={matchTeamId}
        showModal={
          isSubmitResultsModalOpened === true && isRateOpponentModalOpened === false && showModal
        }
        onClose={() => {
          setIsSubmitResultsModalOpened(false);
        }}
        onScoreSubmit={(response) => {
          if (response.id) {
            setIsSubmitResultsModalOpened(false);
            setIsRateOpponentModalOpened(true);
          }
        }}
      />
      <SubmitOpponentRateModal
        showModal={
          isSubmitResultsModalOpened === false && isRateOpponentModalOpened === true && showModal
        }
        onClose={() => {
          setIsRateOpponentModalOpened(false);
        }}
        matchTeamId={matchTeamId}
      />
    </>
  );
};

export default MatchReportModalsController;
