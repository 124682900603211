import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import classNames from "classnames";
import { type FC } from "react";

interface LoadingColumnsProps {
  matchesCount?: number;
  maxCount?: number;
  withLabels?: boolean;
  withPlayButton?: boolean;
  tournamentType?: ClientModel<TournamentModel>["type"];
}

const LoadingColumns: FC<LoadingColumnsProps> = ({
  withPlayButton,
  withLabels,
  matchesCount,
  maxCount,
  tournamentType,
}) => {
  return (
    <>
      <div className="w-full">
        {Array(matchesCount)
          .fill(undefined)
          ?.map((item, index) => (
            <div key={item} className="animate-animatePulseOverlay1 pt-5 tb:pt-6 dk:pt-7">
              {withLabels && (
                // <Link to={`/tournament/${tournamentId}/match/${item.id}`}>
                //   <h4 className="flex w-fit font-Unbounded text-lg tb:text-xl dk:text-3xl">
                //     <p className="effect-underline relative">Matchweek {item.bracketPosition}</p>
                //   </h4>
                // </Link>
                <div></div>
              )}
              <div
                className={classNames(
                  "grid w-full cursor-pointer grid-cols-[70%_30%] pt-2 tb:pt-3 dk:pt-4",
                )}
              >
                <div className="row-[2/2] m-[1px] min-h-[80px] content-center rounded-bl-xl bg-white px-3 py-4 tb:p-5 dk:py-7">
                  <div className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6" key={item}>
                    <div
                      className={classNames(
                        "grid max-h-[32px] grid-cols-1 grid-rows-3 items-center",
                      )}
                    >
                      {/* {item.orderedResult.teams?.[1].members?.map((player) => (
                      <Link key={player.id} to={`/user/${player.user}`} className="contents">
                        <PlayerAvatar
                          highlighted={player?.user.toString() === authState?.userId}
                          image={player?.avatar?.image ?? ""}
                          classNameWrapper={classNames("dk:w-12 dk:h-12 tb:w-10 tb:h-10 w-8 h-8", {
                            "z-[10]": index === 0,
                            "z-[11]": index === 1,
                            "z-[12]": index === 2,
                          })}
                          className="h-8 w-8 bg-clip-content p-[2px] tb:h-10 tb:w-10 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                        />
                      </Link>
                    ))} */}
                    </div>
                    {/* <Link to={`/team/${item.orderedResult.teams[1].id}`}>
                    <span
                      className={classNames("text-xs leading-6 tb:text-xl dk:text-2xl", {
                        "text-fonts-accentColor": isOwnTeam(
                          item.orderedResult.teams[1],
                          authState?.userId,
                        ),
                        "text-fonts-darken": !isOwnTeam(
                          item.orderedResult.teams[1],
                          authState?.userId,
                        ),
                        "font-semibold":
                          item.winner !== undefined &&
                          item.winner === item.orderedResult.teams?.[1].id,
                      })}
                    >
                      {item.orderedResult?.teams?.[1]?.name}
                    </span>
                  </Link> */}
                  </div>
                </div>
                <div className="row-[1/2] m-[1px] min-h-[80px] content-center rounded-tl-xl bg-white px-3 py-4 tb:p-5 dk:py-7">
                  <div className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6" key={item}>
                    <div
                      className={classNames(
                        "grid max-h-[32px] grid-cols-1 grid-rows-3 items-center",
                      )}
                    >
                      {/* {item.orderedResult.teams?.[0].members?.map((player, index) => (
                      <Link key={player.id} to={`/user/${player.user}`} className="contents">
                        <PlayerAvatar
                          highlighted={player?.user.toString() === authState?.userId}
                          image={player?.avatar?.image ?? ""}
                          classNameWrapper={classNames("dk:w-12 dk:h-12 tb:w-10 tb:h-10 w-8 h-8", {
                            "z-[10]": index === 0,
                            "z-[11]": index === 1,
                            "z-[12]": index === 2,
                          })}
                          className="h-8 w-8 bg-clip-content p-[2px] tb:h-10 tb:w-10 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                        />
                      </Link>
                    ))} */}
                    </div>
                    {/* <Link to={`/team/${item.orderedResult.teams[0].id}`}> */}
                    {/* <span
                      className={classNames("text-xs leading-6 tb:text-xl dk:text-2xl", {
                        "text-fonts-accentColor": isOwnTeam(
                          item.orderedResult.teams[0],
                          authState?.userId,
                        ),
                        "text-fonts-darken": !isOwnTeam(
                          item.orderedResult.teams[0],
                          authState?.userId,
                        ),
                        "font-semibold":
                          item.winner !== undefined &&
                          item.winner === item.orderedResult.teams?.[0].id,
                      })}
                    >
                      {item.orderedResult?.teams?.[0]?.name}
                    </span> */}
                    {/* </Link> */}
                  </div>
                </div>
                <div className={classNames("row-[1/3] m-[1px] flex rounded-r-xl bg-white")}>
                  {/* {item.winner && (
                  <MatchResultsScores
                    orderedResult={item.orderedResult}
                    className="w-full"
                    winnerTeamId={item.winner.toString()}
                    extraSpacing
                  />
                )}
                {!item.winner && withPlayButton && (
                  <Button
                    style={ButtonStyle.Primary}
                    buttonFontClass={ButtonFontStyle.Unbounded}
                    onClick={() => {
                      navigate(`/tournament/${tournamentId}/match/${item.id}`);
                    }}
                    className="self-center rounded-xl px-3 transition-transform hover:scale-105"
                  >
                    PLAY
                  </Button>
                )} */}
                  {!withPlayButton && (
                    <div className="grid grid-cols-3 grid-rows-2 gap-4 px-3 py-3 text-4xl text-fonts-optional opacity-70 tb:gap-6 tb:px-3 tb:py-5 tb:text-5xl dk:gap-6 dk:px-5 dk:py-7 dk:text-6xl">
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default LoadingColumns;
