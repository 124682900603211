import {
  type ReactElement,
  useEffect,
  useState,
  Suspense,
  type PropsWithChildren,
  useCallback,
  type FC,
  useMemo,
} from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import {
  AnonimousRoutes,
  IntermediateRoutes,
  RegistrationCompletedRoutes,
  RegistrationWithoutProfileRoutes,
} from "./routes";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, createTheme } from "baseui";
import {
  DefaultFilterState,
  EmptyFilterValue,
  FiltersContext,
  type FiltersState,
} from "./context/filter";

import "./index.css";
import useAuth, { useAuthInit } from "./hooks/useAuth";
import { type AuthState } from "./context/auth";
import { type ClientModel } from "./endpoints/adapter/client-models";
import { type UserSettings, type UserProfile } from "./endpoints/models/@types/user";
import { ProfileContext } from "./context/profile";

import usePromocode from "./hooks/usePromocode";
import Loader from "./components/loaders/default";

import useActualUserProfile from "./hooks/useActualUserProfile";
import useActualUserSettings from "./hooks/useActualUserSettings";
import Messenger from "./MinChatMessenger";
import useFirebaseNotification from "./hooks/useFirebaseNotification";
import SurveyModalController from "./SurveyModalController";
import { TournamentDetailContext } from "./context/tournament-detail";
import { useTournamentsCacheInit } from "./hooks/useTournaments";
// import axios from "axios";

const engine = new Styletron();
// eslint-disable-next-line react/display-name
const getRoutesByAuthState = ({
  authState,
  isLoading,
  settings,
  profile,
}: {
  authState: AuthState | null;
  settings: ClientModel<UserSettings> | null | undefined;
  profile: ClientModel<UserProfile> | null | undefined;
  isLoading: boolean;
}): ReactElement<any, any> => {
  if (isLoading) return IntermediateRoutes();
  if (!authState?.userId) {
    return AnonimousRoutes();
  } else if (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (profile && profile.isEmptyProfile === true) ||
    (settings && settings.isEmptySettings === true)
  ) {
    return RegistrationWithoutProfileRoutes();
  } else if (profile && !profile.isEmptyProfile && settings && !settings.isEmptySettings) {
    return RegistrationCompletedRoutes();
  }
  return IntermediateRoutes();
};
const baseUitheme = createTheme(
  {},
  {
    colors: {
      ...LightTheme?.colors,
      // borderOpaque: "#facfac",
      primary: "#19A1FC",
      // backgroundSecondary: "red",
    },
  },
);

interface PromocodeProps extends PropsWithChildren {
  profile: ClientModel<UserProfile> | null | undefined;
  settings: ClientModel<UserSettings> | null | undefined;
}
function Promocode({ children }: PromocodeProps): JSX.Element {
  const { isAuthenticated, authState } = useAuth();
  const isRedirectActive = !!authState || !isAuthenticated();
  usePromocode(
    authState
      ? { isEmptyProfile: !!authState.isEmptyProfile, isEmptySettings: !!authState.isEmptySettings }
      : undefined,
    isRedirectActive,
  );

  return <>{children}</>;
}

const CurrentUserController: FC<
  PropsWithChildren & {
    profileState: ReturnType<typeof useActualUserProfile>;
    settingsState: ReturnType<typeof useActualUserSettings>;
    onLoadStart?: () => void;
    onLoadSuccess?: () => void;
  }
> = ({
  children,
  profileState: { profile, setProfile, fetch: syncProfile, status: profileStatus },
  settingsState: { settings, setSettings, fetch: syncSettings, status: settingsStatus },
  onLoadStart,
  onLoadSuccess,
}) => {
  const location = useLocation();
  const syncCurrentUser = useCallback(async () => {
    onLoadStart?.();
    const [actualProfile, actualSettings] = await Promise.allSettled([
      syncProfile(),
      syncSettings(),
    ]);
    if (actualProfile.status === "fulfilled") {
      if (actualProfile.value.result) {
        setProfile(actualProfile.value.result);
      }
      if (actualProfile.value.error) {
        setProfile(() => null);
      }
    }
    if (actualSettings.status === "fulfilled") {
      if (actualSettings.value.result) {
        setSettings(actualSettings.value.result);
      }
      if (actualSettings.value.error) {
        setSettings(() => null);
      }
    }
    onLoadSuccess?.();
  }, [syncProfile, syncSettings]);

  useEffect(() => {
    void syncCurrentUser();
  }, [location.pathname]);
  useEffect(() => {
    if ((profile === null || profile) && (settings === null || settings)) {
      onLoadSuccess?.();
    }
  }, [profile, settings]);
  return (
    <ProfileContext.Provider value={{ profile, setProfile, settings, setSettings }}>
      {children}
    </ProfileContext.Provider>
  );
};

const useIntercomInit = (profile: ClientModel<UserProfile> | null | undefined): void => {
  useEffect(() => {
    if (profile) {
      const settings: typeof window.intercomSettings = {
        app_id: "f6a6j5iv",
        user_id: profile.id.toString(),
        name: profile.firstName,
        email: profile.email,
        phone: profile.phone,
        avatar: {
          type: "avatar",
          image_url: profile.avatar?.image ?? "",
        },
      };
      // runtime check for Intercom existance
      if (window.Intercom !== undefined) {
        Intercom("update", settings);
      } else {
        window.intercomSettings = settings;
      }
    }
  }, [profile, window.Intercom]);
};

function App(): JSX.Element {
  const [matchFilters, setMatchFilters] = useState<FiltersState | undefined>(DefaultFilterState);
  const { Provider: AuthProvider, value: authController } = useAuthInit();
  const {
    profile,
    setProfile,
    fetch: syncProfile,
    status: profileStatus,
  } = useActualUserProfile(authController);

  useIntercomInit(profile);

  const {
    settings,
    setSettings,
    fetch: syncSettings,
    status: settingsStatus,
  } = useActualUserSettings(authController);
  useEffect(() => {
    if (settings) {
      setMatchFilters({
        gender: settings.gender ?? EmptyFilterValue,
        level: settings.level ?? EmptyFilterValue,
        city: settings.city ?? EmptyFilterValue,
        status: EmptyFilterValue,
      });
    }
  }, [settings]);

  const [isRoutesLoaded, setIsRoutesLoaded] = useState(false);
  const Routes = useMemo(
    () =>
      getRoutesByAuthState({
        authState: authController.auth,
        isLoading: isRoutesLoaded,
        settings,
        profile,
      }),
    [authController, settings, profile, isRoutesLoaded],
  );
  // useEffect(() => {
  //   (axios as any).cache.store = {};
  // }, []);
  useFirebaseNotification();
  // console.log(notifications);
  const {
    leaderboard,
    tournamentMatches,
    currentUserMatches,
    tournaments,
    setTournaments,
    setCurrentUserMatches,
    setTournamentMatches,
    setLeaderboard,
  } = useTournamentsCacheInit();
  return (
    <BrowserRouter>
      <FiltersContext.Provider value={{ filters: matchFilters, setFilters: setMatchFilters }}>
        <AuthProvider value={authController}>
          <TournamentDetailContext.Provider
            value={{
              leaderboard,
              tournamentMatches,
              currentUserMatches,
              tournaments,
              setTournaments,
              setCurrentUserMatches,
              setTournamentMatches,
              setLeaderboard,
            }}
          >
            <CurrentUserController
              settingsState={{ settings, setSettings, fetch: syncSettings, status: settingsStatus }}
              profileState={{ profile, setProfile, fetch: syncProfile, status: profileStatus }}
              onLoadStart={() => {
                setIsRoutesLoaded(true);
              }}
              onLoadSuccess={() => {
                setIsRoutesLoaded(false);
              }}
            >
              <Promocode profile={profile} settings={settings}>
                <StyletronProvider value={engine}>
                  <BaseProvider theme={baseUitheme} zIndex={50}>
                    <Suspense
                      fallback={
                        <div className="absolute h-full w-full">
                          <Loader />
                        </div>
                      }
                    >
                      <Messenger
                        userId={authController?.auth?.userId}
                        userFullName={profile?.firstName}
                        userAvatar={profile?.avatar?.image}
                      >
                        <SurveyModalController>{Routes}</SurveyModalController>
                      </Messenger>
                    </Suspense>
                  </BaseProvider>
                </StyletronProvider>
              </Promocode>
            </CurrentUserController>
          </TournamentDetailContext.Provider>
        </AuthProvider>
      </FiltersContext.Provider>
    </BrowserRouter>
  );
}

export default App;
