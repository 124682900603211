import { type FC } from "react";
import TournamentHeader from "./parts/tournament-header";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import { type TournamentMatchListAPIModel } from "@double-bagel/endpoints/models/@types/match";
import TournamentMatchesColumn from "@double-bagel/components/widgets/tournament-matches-column/tournament-matches-column";
import TournamentMatchesColumnLoading from "@double-bagel/components/widgets/tournament-matches-column/tournament-matches-column-loading";

type TournamentMatchesReportCardProps = {
  tournament: ClientModel<TournamentModel>;
  currentUserMatches: Array<ClientModel<TournamentMatchListAPIModel>>;
  isMatchesLoading: boolean;
};
const TournamentMatchesReportCard: FC<TournamentMatchesReportCardProps> = ({
  tournament,
  currentUserMatches,
  isMatchesLoading,
}) => {
  return (
    <>
      <TournamentHeader
        tournamentId={tournament.id}
        tournamentTitle={tournament.title}
        tournamentType={tournament.type}
        deadline={tournament.deadline}
        isFinished={!!tournament.winner?.id}
      />
      {isMatchesLoading && (
        <TournamentMatchesColumnLoading tournamentType={tournament.type} withLabels={false} />
      )}
      {!isMatchesLoading && (
        <TournamentMatchesColumn
          withLabels={false}
          matches={currentUserMatches}
          tournamentType={tournament.type}
        />
      )}
    </>
  );
};

export default TournamentMatchesReportCard;
