import { useState, type FC } from "react";
import {
  type TournamentMatchTeamData,
  type TournamentMatchListAPIModel,
} from "@double-bagel/endpoints/models/@types/match";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import PlayerAvatar from "@double-bagel/components/avatar/default";
import useAuth from "@double-bagel/hooks/useAuth";
import { Button } from "@double-bagel/components/buttons";
import { ButtonStyle, ButtonFontStyle } from "@double-bagel/components/buttons/default-button";
import MatchResultsScores from "@double-bagel/components/cards/match-results-scores";
import MatchReportModalsController from "@double-bagel/components/modal/compositions/match-report/match-report-controller";
import { TournamentMatchListAPIModelMethods } from "@double-bagel/endpoints/models/methods/match";

interface ChallengeColumnsProps {
  matches?: ClientModel<TournamentMatchListAPIModel>[];
  maxCount?: number;
  withLabels?: boolean;
  withPlayButton?: boolean;
}

const isOwnTeam = (team: ClientModel<TournamentMatchTeamData>, currentUserId?: string): boolean => {
  if (!currentUserId) return false;
  return !!team?.members
    .map((player) => player.userId)
    .find((userId) => userId?.toString() === currentUserId);
};

const ChallengeColumns: FC<ChallengeColumnsProps> = ({
  matches,
  withLabels = true,
  withPlayButton = true,
}) => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const tournamentId = matches?.[0]?.tournament;
  const [isMatchReportModalsOpened, setIsMatchReportModalsOpened] = useState(false);
  const [tournamentMatchTeamId, setTournamentMatchTeamId] =
    useState<ClientModel<TournamentMatchListAPIModel>["currentUserMatchTeam"]>();
  const wrappedMatches = matches?.map((item) => new TournamentMatchListAPIModelMethods(item));
  return (
    <>
      <div className="w-full">
        {wrappedMatches?.map((item: TournamentMatchListAPIModelMethods, index: number) => (
          <>
            <div key={item.data.id} className="pt-5 tb:pt-6 dk:pt-7">
              {withLabels && (
                <Link to={`/tournament/${tournamentId}/match/${item.data.id}`}>
                  <h4 className="flex w-fit font-Unbounded text-lg tb:text-xl dk:text-3xl">
                    <p className="effect-underline relative">
                      Matchweek {item.data.bracketPosition}
                    </p>
                  </h4>
                </Link>
              )}
              <div
                className={classNames(
                  "grid w-full cursor-pointer grid-cols-[70%_30%] pt-2 tb:pt-3 dk:pt-4",
                )}
              >
                <div className="row-[2/2] m-[1px] min-h-[80px] content-center rounded-bl-xl bg-white px-3 py-4 tb:p-5 dk:py-7">
                  <div
                    className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6"
                    key={item.data.id}
                  >
                    <div
                      className={classNames("grid grid-cols-1 items-center", {
                        "max-h-[48px] grid-rows-3":
                          item.getOpponentTournamentMatchTeam()?.members?.length === 3,
                        "max-h-[32px] grid-rows-2 ":
                          item.getOpponentTournamentMatchTeam()?.members?.length === 2,
                      })}
                    >
                      {item.getOpponentTournamentMatchTeam()?.members?.map((member) => (
                        <Link
                          key={member.userId}
                          to={`/user/${member.userId}`}
                          className="contents"
                        >
                          <PlayerAvatar
                            highlighted={member.userId.toString() === authState?.userId}
                            image={member?.avatar ?? ""}
                            classNameWrapper={classNames(
                              "dk:w-12 dk:h-12 tb:w-10 tb:h-10 w-8 h-8",
                              {
                                "z-[10]": index === 0,
                                "z-[11]": index === 1,
                                "z-[12]": index === 2,
                              },
                            )}
                            className="h-8 w-8 bg-clip-content p-[2px] tb:h-10 tb:w-10 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                          />
                        </Link>
                      ))}
                    </div>
                    <Link to={`/team/${item.getOpponentTournamentMatchTeam()?.teamId}`}>
                      <span
                        className={classNames("text-xs leading-6 tb:text-xl dk:text-2xl", {
                          "text-fonts-accentColor": isOwnTeam(
                            item.getOpponentTournamentMatchTeam(),
                            authState?.userId,
                          ),
                          "text-fonts-darken": !isOwnTeam(
                            item.getOpponentTournamentMatchTeam(),
                            authState?.userId,
                          ),
                          "font-semibold":
                            item.data.winner !== undefined &&
                            item.data.winner === item.getOpponentTournamentMatchTeam().teamId,
                        })}
                      >
                        {item.getOpponentTournamentMatchTeam().name}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="row-[1/2] m-[1px] min-h-[80px] content-center rounded-tl-xl bg-white px-3 py-4 tb:p-5 dk:py-7">
                  <div
                    className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6"
                    key={item.data.id}
                  >
                    <div
                      className={classNames("grid grid-cols-1 items-center", {
                        "max-h-[48px] grid-rows-3":
                          item.getCurrentUserTournamentMatchTeam().members.length === 3,
                        "max-h-[32px] grid-rows-2":
                          item.getCurrentUserTournamentMatchTeam().members.length === 2,
                      })}
                    >
                      {item.getCurrentUserTournamentMatchTeam().members?.map((member, index) => (
                        <Link
                          key={member.userId}
                          to={`/user/${member.userId}`}
                          className="contents"
                        >
                          <PlayerAvatar
                            highlighted={member?.userId.toString() === authState?.userId}
                            image={member?.avatar ?? ""}
                            classNameWrapper={classNames(
                              "dk:w-12 dk:h-12 tb:w-10 tb:h-10 w-8 h-8",
                              {
                                "z-[10]": index === 0,
                                "z-[11]": index === 1,
                                "z-[12]": index === 2,
                              },
                            )}
                            className="h-8 w-8 bg-clip-content p-[2px] tb:h-10 tb:w-10 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                          />
                        </Link>
                      ))}
                    </div>
                    <Link to={`/team/${item.getCurrentUserTournamentMatchTeam().teamId}`}>
                      <span
                        className={classNames("text-xs leading-6 tb:text-xl dk:text-2xl", {
                          "text-fonts-accentColor": isOwnTeam(
                            item.getCurrentUserTournamentMatchTeam(),
                            authState?.userId,
                          ),
                          "text-fonts-darken": !isOwnTeam(
                            item.getCurrentUserTournamentMatchTeam(),
                            authState?.userId,
                          ),
                          "font-semibold":
                            item.data.winner !== undefined &&
                            item.data.winner === item.getCurrentUserTournamentMatchTeam().teamId,
                        })}
                      >
                        {item.getCurrentUserTournamentMatchTeam().name}
                      </span>
                    </Link>
                  </div>
                </div>
                <div
                  className={classNames("row-[1/3] m-[1px] flex rounded-r-xl bg-white", {
                    "justify-center": !item.data.winner,
                  })}
                >
                  {item.data.winner && item.getMatchScores() && (
                    <MatchResultsScores
                      team1={item.getCurrentUserTournamentMatchTeam().teamId}
                      team2={item.getOpponentTournamentMatchTeam().teamId}
                      scores={item.getMatchScores()}
                      className="w-full"
                      winnerTeamId={item.data.winner.toString()}
                      extraSpacing
                    />
                  )}
                  {!item.data.winner && withPlayButton && (
                    <Button
                      style={ButtonStyle.Primary}
                      buttonFontClass={ButtonFontStyle.Unbounded}
                      onClick={() => {
                        navigate(`/tournament/${tournamentId}/match/${item.data.id}`);
                      }}
                      className="self-center rounded-xl px-3 transition-transform hover:scale-105"
                    >
                      PLAY
                    </Button>
                  )}
                  {!item.data.winner && !withPlayButton && (
                    <div className="grid grid-cols-3 grid-rows-2 gap-4 px-3 py-3 text-4xl text-fonts-optional opacity-70 tb:gap-6 tb:px-3 tb:py-5 tb:text-5xl dk:gap-6 dk:px-5 dk:py-7 dk:text-6xl">
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!item?.data.currentUserFeedback && item.data.winner && (
              <>
                <div className="grid grid-flow-col content-center gap-3 pt-2 tb:grid-flow-col tb:gap-4 tb:pt-3 dk:grid-flow-col dk:gap-6 dk:pt-4">
                  <Button
                    style={ButtonStyle.Secondary}
                    onClick={() => {
                      setIsMatchReportModalsOpened(true);
                      setTournamentMatchTeamId(item.getCurrentUserTournamentMatchTeam().teamId);
                    }}
                  >
                    Rate opponent
                  </Button>
                </div>
              </>
            )}
          </>
        ))}
      </div>
      {tournamentId && tournamentMatchTeamId && (
        <MatchReportModalsController
          tournamentId={tournamentId}
          matchTeamId={tournamentMatchTeamId}
          showModal={isMatchReportModalsOpened}
          onClose={() => {
            setIsMatchReportModalsOpened(false);
          }}
          specificModal={"rate"}
        />
      )}
    </>
  );
};

export default ChallengeColumns;
