import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type AuthState } from "@double-bagel/context/auth";
import { useEffect, useState } from "react";
import { type UserSettings } from "@double-bagel/endpoints/models/@types/user";
import { useGetSetiings } from "@double-bagel/endpoints/endpoints.user";
import { type AsyncStatuses } from "./useAsync";
import { type AsHookWrappedFunc } from "@double-bagel/endpoints/adapter/hook";

type useActualUserSettingsReturnType = {
  settings: ClientModel<UserSettings> | null | undefined;
  setSettings: React.Dispatch<React.SetStateAction<ClientModel<UserSettings> | null | undefined>>;
  status: AsyncStatuses;
  fetch: () => Promise<AsHookWrappedFunc<UserSettings>>;
};
const useActualUserSettings = ({
  auth,
  setAuth,
}: {
  auth: AuthState | null;
  setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
}): useActualUserSettingsReturnType => {
  const { value, error, execute, status } = useGetSetiings();
  const [settings, setSettings] = useState<ClientModel<UserSettings> | null | undefined>();
  useEffect(() => {
    if (!error && value) {
      setSettings(value);
    }
  }, [value, error]);
  useEffect(() => {
    if (settings) {
      setAuth((state) => ({
        ...state,
        isEmptySettings: !!settings.isEmptySettings,
      }));
    }
  }, [settings]);

  return {
    settings,
    setSettings,
    status,
    fetch: execute,
  };
};

export default useActualUserSettings;
