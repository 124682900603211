import { type FC } from "react";

import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentMatchListAPIModel } from "@double-bagel/endpoints/models/@types/match";
import TournamentMatchesReportCard from "../cards/tournament/report/tournament-matches-report";

type TournamentMatchReportWidgetProps = {
  tournament: ClientModel<TournamentModel>;
  currentUserMatches: Array<ClientModel<TournamentMatchListAPIModel>>;
  isMatchesLoading: boolean;
};
const TournamentMatchReportWidget: FC<TournamentMatchReportWidgetProps> = ({
  tournament,
  currentUserMatches,
  isMatchesLoading,
}) => {
  // const isFinished = !!match?.orderedResult?.results?.[0] || !!match?.orderedResult?.results?.[1];
  // const currentUserMatchTeam = match?.tournamentMatchTeams?.[0];
  // const oppoTeam = match?.orderedResult?.teams?.[1];
  return (
    <>
      <TournamentMatchesReportCard
        tournament={tournament}
        currentUserMatches={currentUserMatches}
        isMatchesLoading={isMatchesLoading}
      />
    </>
  );
};

export default TournamentMatchReportWidget;
