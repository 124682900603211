import React from "react";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import {
  type TournamentModel,
  type TournamentLeadearboardChallengeSerializer,
} from "@double-bagel/endpoints/models/@types/tournament";
import {
  type TournamentMatchListAPIModel,
  type TournamentMatchModel,
} from "@double-bagel/endpoints/models/@types/match";

export const DefaultTournamentDetailState = {};

type LeaderboardState = {
  [tournamentId: number]: ClientModel<TournamentLeadearboardChallengeSerializer>[];
};
type TournamentMatchesState = {
  [tournamentId: number]: ClientModel<TournamentMatchListAPIModel>[];
};
type CurrentUserMatchesState = {
  [tournamentId: number]: ClientModel<TournamentMatchModel>[];
};
type TournamentState = { [tournamentId: number]: ClientModel<TournamentModel> };

export type TournamentDetailContextType = {
  leaderboard: LeaderboardState | null | undefined;
  tournamentMatches: TournamentMatchesState | null | undefined;
  currentUserMatches: CurrentUserMatchesState | null | undefined;
  tournaments: TournamentState | null | undefined;
} & {
  setLeaderboard: React.Dispatch<React.SetStateAction<LeaderboardState | null | undefined>>;
  setTournamentMatches: React.Dispatch<
    React.SetStateAction<TournamentMatchesState | null | undefined>
  >;
  setCurrentUserMatches: React.Dispatch<
    React.SetStateAction<CurrentUserMatchesState | null | undefined>
  >;
  setTournaments: React.Dispatch<React.SetStateAction<TournamentState | null | undefined>>;
};
export const TournamentDetailContext = React.createContext<TournamentDetailContextType>({
  setLeaderboard: () => {},
  setTournamentMatches: () => {},
  setCurrentUserMatches: () => {},
  setTournaments: () => {},
  leaderboard: null,
  tournamentMatches: null,
  currentUserMatches: null,
  tournaments: null,
});
