import { type FC, useState } from "react";
import Image from "../image";
import LockImage from "assets/Icons/mono/padlock.svg";
import classNames from "classnames";
import { type PlayerModel } from "@double-bagel/endpoints/models/@types/player";
import { type APIResponse } from "@double-bagel/endpoints/models/@types";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface TournamentParticipantsProps {
  players: APIResponse.NestedCamelize<PlayerModel>[];
  totalSlots: number;
}

interface PlayerColelctionSlotProps {
  image?: string;
  className?: string;
}

const TournamentParticipantAvatar: FC<PlayerColelctionSlotProps> = ({ image, className }) => {
  const [loaded, setLoaded] = useState(false);

  if (image) {
    return (
      <>
        {!loaded && (
          <div className={classNames("animate-pulse rounded-full bg-[#D9D9D9]", className)} />
        )}
        <LazyLoadImage
          src={image}
          alt={"Player Slot"}
          className={classNames("aspect-[1] bg-[#D9D9D9] object-cover object-center", className, {
            hidden: !loaded,
            block: loaded,
          })}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </>
    );
  } else {
    return <div className={classNames("bg-[#D9D9D9]", className)} />;
  }
};

const TournamentCardPlayers: FC<TournamentParticipantsProps> = ({ players, totalSlots }) => {
  return (
    <div className="flex flex-col">
      <div className="relative flex flex-row items-center gap-0">
        {players
          .concat(new Array(Math.max(0, totalSlots - players.length)).fill({}))
          .map((player, index) => {
            return (
              <TournamentParticipantAvatar
                key={index}
                image={player.profile?.avatar?.image}
                className={classNames(
                  "relative h-9 w-9 rounded-full border-[2px] border-solid border-[#FFFFFF] tb:h-12 tb:w-12 tb:border-[3px] dk:h-[75px] dk:w-[75px] dk:border-[4px]",
                  {
                    "-ml-3 tb:-ml-4 dk:-ml-[20px]": index !== 0,
                  },
                )}
              />
            );
          })}
      </div>
      <div className="flex items-center gap-1.5 text-xs text-fonts-optional tb:gap-2 sm:text-sm dk:gap-2.5">
        {players.length === totalSlots && (
          <Image
            src={LockImage}
            className="h-3 w-2 tb:h-[14px] tb:w-3 dk:h-5 dk:w-4"
            alt="LockImage"
          />
        )}
        <span className="text-xs leading-4 tb:pl-0.5 tb:pt-1.5 tb:text-base tb:leading-6 dk:pl-[3px] dk:pt-2 dk:text-xl dk:leading-8">
          {players.length}/{totalSlots} joined
        </span>
      </div>
    </div>
  );
};

export default TournamentCardPlayers;
