import { type BackendModels } from "@double-bagel/endpoints/models/@types/models";
import classNames from "classnames";
import { type FC } from "react";

const MatchResultsScores: FC<{
  scores: Array<[number?, number?, number?]>;
  team1: BackendModels.ID;
  team2: BackendModels.ID;
  className?: string;
  winnerTeamId?: string;
  extraSpacing?: boolean;
}> = ({ team1, team2, scores, className, winnerTeamId, extraSpacing = false }) => {
  let winner: "top" | "bot" | undefined;
  if (team1) {
    if (team1.toString() === winnerTeamId) {
      winner = "top";
    }
  }
  if (team2) {
    if (team2?.toString() === winnerTeamId) {
      winner = "bot";
    }
  }
  const winnerFlags: any[] = [
    [
      (scores?.[0]?.[0] ?? -1) > (scores?.[1]?.[0] ?? -1),
      (scores?.[0]?.[1] ?? -1) > (scores?.[1]?.[1] ?? -1),
      (scores?.[0]?.[2] ?? -1) > (scores?.[1]?.[2] ?? -1),
    ],
    [
      (scores?.[0]?.[0] ?? -1) < (scores?.[1]?.[0] ?? -1),
      (scores?.[0]?.[1] ?? -1) < (scores?.[1]?.[1] ?? -1),
      (scores?.[0]?.[2] ?? -1) < (scores?.[1]?.[2] ?? -1),
    ],
  ];
  return (
    <div className={classNames(className, "relative")}>
      <div
        className={classNames(
          "triangle-left absolute right-0 h-[5px] w-[7px] scale-100 tb:right-[2px] tb:scale-[150%] dk:right-[3px] dk:scale-[200%]",
          {
            "top-[18%] tb:top-[10%] dk:top-[20%]": winner === "top" && !extraSpacing,
            "top-[72%] dk:top-[75%]": winner === "bot" && !extraSpacing,
            "top-[22%] tb:top-[22%] dk:top-[21%]": winner === "top" && extraSpacing,
            "top-[73%] tb:top-[71%] dk:top-[75%]": winner === "bot" && extraSpacing,
            invisible: !winner,
          },
        )}
      />
      <div
        className={classNames(
          "grid h-full grid-cols-3 grid-rows-2 items-center font-Inter text-sm  tb:text-2xl dk:text-3xl",
          { "gap-x-2 gap-y-3 px-3 py-3 tb:px-3 tb:py-5 dk:px-5 dk:py-9 ": !extraSpacing },
          { "self-center": extraSpacing },
        )}
      >
        {Array.isArray(scores) && scores?.[0]?.length === 1 && (
          <>
            <div className="row-start-1 flex" />
            <div className="row-start-2 flex" />
            <div className="row-start-1 flex" />
            <div className="row-start-2 flex" />
          </>
        )}
        {Array.isArray(scores) && scores?.[0]?.length === 2 && (
          <>
            <div className="row-start-1 flex" />
            <div className="row-start-2 flex" />
          </>
        )}
        {scores?.[0]?.length ? (
          scores?.map((item, gameNum) =>
            item.map((i, index) => (
              <div
                key={`${index}-${gameNum}`}
                className={classNames(
                  "flex w-full justify-center text-center before:content-[attr(content-attr)]",
                  {
                    "row-start-2 self-end": gameNum % 2 !== 0,
                    "row-start-1 self-start": gameNum % 2 === 0,
                    "text-fonts-optional": !winnerFlags[gameNum][index],
                    "text-fonts-darken": winnerFlags[gameNum][index],
                    "self-center": extraSpacing,
                  },
                )}
                {...{ "content-attr": `${i === undefined ? "-" : i}` }}
              />
            )),
          )
        ) : (
          <>
            <div className="row-start-1 flex" />
            <div className="row-start-2 flex" />
            <div
              className={classNames("row-start-1 flex justify-center self-start", {
                "text-fonts-optional": winner !== "top",
              })}
            >
              {winner === "top" ? "win" : "ret"}
            </div>
            <div
              className={classNames("row-start-2 flex justify-center self-end", {
                "text-fonts-optional": winner !== "bot",
              })}
            >
              {winner === "bot" ? "win" : "ret"}
            </div>
            <div className="row-start-1 flex" />
            <div className="row-start-2 flex" />
          </>
        )}
      </div>
    </div>
  );
};

export default MatchResultsScores;
