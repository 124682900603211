import axios from "axios";
import _ from "lodash";
import asHook from "endpoints/adapter/hook";
import { type BackendModels } from "./models/@types/models";
import "./conf";
import { type FiltersState, EmptyFilterValue } from "@double-bagel/context/filter";
import {
  type TournamentLeadearboardChallengeSerializer,
  type TournamentLeadearboardSerializer,
  type TournamentModel,
} from "./models/@types/tournament";
import { queryParamsFromDict } from "./adapter/util";
import { type ClientModel } from "./adapter/client-models";
import { type TournamentMatchListAPIModel } from "./models/@types/match";

type useGetTournamentFilters = Partial<FiltersState> & {
  forUser: boolean;
  isUserTournament: boolean;
  isCourtReserved: boolean;
  type: "DEFAULT" | "ROUND-ROBIN" | "CHALLENGE" | "DUEL";
};

export const useGetTournaments = asHook(
  async (filters: Partial<useGetTournamentFilters>): Promise<TournamentModel[]> => {
    const result = await axios.get<BackendModels.Tournament[]>(
      `/tournament/?${queryParamsFromDict({
        city: filters.city,
        gender: filters.gender === EmptyFilterValue ? undefined : filters.gender,
        level: filters.level === EmptyFilterValue ? undefined : filters.level,
        state: filters.status === EmptyFilterValue ? undefined : filters.status,
        ...(filters.forUser ? { for_user: filters.forUser.toString() } : {}),
        ...(filters.type ? { type: filters?.type } : {}),
        ...(filters.isUserTournament
          ? { is_user_tournament: filters?.isUserTournament.toString() }
          : {}),
        ...(typeof filters.isCourtReserved === "boolean"
          ? { is_court_reserved: filters?.isCourtReserved.toString() }
          : {}),
      })}`,
    );
    return result.data;
  },
  "Tournament",
);

export const useGetTournamentById = asHook<TournamentModel, [id: number]>(async (id: number) => {
  const result = await axios.get<BackendModels.Tournament>(`/tournament/${id}/`);
  return result.data;
}, "Tournament");

export const useGetCities = asHook<BackendModels.City[], []>(async () => {
  const result = await axios.get<BackendModels.City[]>(`/cities/`);
  return result.data;
}, "City");

export const useGetAchievements = asHook<BackendModels.Achievements[], []>(async () => {
  const result = await axios.get<BackendModels.Achievements[]>(`/achievements/`);
  return result.data;
}, "Achievement");

export const useJoinTournament = asHook(async (tournamentId: string) => {
  const result = await axios.post<BackendModels.Tournament>("/tournament/join/", {
    tournament_id: tournamentId,
  });
  return result.data;
}, "Tournament");

export const useGetCurrentUserTournamentMatches = asHook(async (tournamentId: string) => {
  const result = await axios.get<TournamentMatchListAPIModel[]>(
    `/tournament/${tournamentId}/matches/current/`,
  );
  return result.data;
}, "TournamentMatch");

type GetTournamentMatchesFilters = {
  isFinished?: boolean;
  userId?: number;
};
type GetTournamentMatchesOrdering = "deadline" | "-deadline" | "date_finished" | "-date_finished";
export const useGetTournamentMatches = asHook(
  async (
    tournamentId: number,
    filters?: GetTournamentMatchesFilters,
    ordering?: GetTournamentMatchesOrdering,
  ) => {
    const filterObject = _.omitBy(
      {
        is_finished: filters?.isFinished !== undefined ? String(filters.isFinished) : undefined,
        user_id: filters?.userId,
        ordering,
      },
      _.isNil,
    );
    const result = await axios.get<BackendModels.TournamentMatchListAPI[]>(
      `/tournament/${tournamentId}/matches/`,
      { params: filterObject },
    );
    return result.data;
  },
  "TournamentMatch",
);
export const useGetAllTournamentMatches = asHook(
  async (
    tournamentId: number,
    filters?: GetTournamentMatchesFilters,
    ordering?: GetTournamentMatchesOrdering,
  ) => {
    const filterObject = _.omitBy(
      {
        is_finished: filters?.isFinished !== undefined ? String(filters.isFinished) : undefined,
        user_id: filters?.userId,
        ordering,
      },
      _.isNil,
    );
    const result = await axios.get<BackendModels.TournamentMatch[]>(
      `/tournament/${tournamentId}/matches/all`,
      { params: filterObject },
    );
    return result.data;
  },
  "TournamentMatch",
);
export const useWithdrawTournament = asHook(
  async (tournamentId: string, data: Partial<ClientModel<BackendModels.TournamentWithdraw>>) => {
    const result = await axios.post<BackendModels.TournamentWithdraw>(
      `/tournament/${tournamentId}/withdraw/`,
      {
        reason: data.reason,
        reason_extra: data.reasonExtra,
      },
    );
    return result.data;
  },
  "TournamentWithdraw",
);
export const useWithdrawUpcomingTournament = asHook(async (tournamentId: string) => {
  const result = await axios.patch<BackendModels.Tournament>(
    `/tournament/${tournamentId}/withdraw_upcoming/`,
    { tournament_id: tournamentId },
  );
  return result.data;
}, "TournamentWithdraw");

export const useGetTournamentTeamResults = asHook(async (tournamentId: number) => {
  const result = await axios.get<BackendModels.TeamResult[]>(
    `/tournament/${tournamentId}/team_results/`,
  );
  return result.data;
}, "TeamResult");

export const useGetPerfectTournament = asHook<TournamentModel[], []>(async () => {
  const result = await axios.get<BackendModels.Tournament[]>(`/tournament/perfect_matches/`);
  return result.data;
}, "Tournament");

export const useGetTournamentLeaderboard = asHook<TournamentLeadearboardSerializer[], [number]>(
  async (tournamentId: number) => {
    const result = await axios.get<TournamentLeadearboardSerializer[]>(
      `/tournament/${tournamentId}/leaderboard/`,
    );
    return result.data;
  },
  "TournamentLeaderboard",
);
export const useGetTournamentLeaderboardChallenge = asHook<
  TournamentLeadearboardChallengeSerializer[],
  [number]
>(async (tournamentId: number) => {
  const result = await axios.get<TournamentLeadearboardChallengeSerializer[]>(
    `/tournament/${tournamentId}/leaderboard_challenge/`,
  );
  return result.data;
}, "TournamentLeaderboard");

type CreateUserTournamentData = {
  place: string;
  when: string;
  gender: TournamentModel["gender"];
  level: number;
  hasCourtReservation: boolean;
  note: string;
  lat: number;
  lon: number;
  city: string;
  address: string;
};
export const useCreateUserTournament = asHook(async (data: CreateUserTournamentData) => {
  const result = await axios.post<BackendModels.Tournament>("/tournament/", {
    place: data.place,
    when: data.when,
    gender: data.gender,
    level: data.level,
    has_court_reservation: data.hasCourtReservation,
    note: data.note,
    lat: data.lat,
    lon: data.lon,
    address: data.address,
    city: data.city,
  });
  return result;
}, "Tournament");
