import axios from "axios";
import { type BackendModels } from "./models/@types/models";
import asHook from "./adapter/hook";
import { type ClientModel } from "./adapter/client-models";

export const useCreateFCMDevice = asHook(
  async (data: Partial<ClientModel<BackendModels.Device>>): Promise<BackendModels.Device> => {
    const result = await axios.post<BackendModels.Device>(`devices/`, {
      name: data.name,
      registration_id: data.registrationId,
      device_id: data.deviceId,
      active: data.active,
      type: data.type,
    });
    return result.data;
  },
  "FCMDevice",
);
