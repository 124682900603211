// export interface TournamentMatchListAPIModel extends BackendModels.BaseModel {
//     tournament: BackendModels.ID;
//     winner: BackendModels.ID;
//     current_user_feedback: BackendModels.ID;
//     current_user_match_team: BackendModels.ID;
//     bracket_position: number;
//     tournament_match_teams: ClientModel<{
//       [match_team_id: number]: {
//         team_id: BackendModels.ID;
//         user_id: BackendModels.ID;
//         name: string;
//         avatar: string;
//         own_score: Array<[number?, number?, number?]>;
//         oppo_score: Array<[number?, number?, number?]>;
//         members: Array<ClientModel<{ user_id: number; name: string; avatar: string }>>;
//       };
//     }>;
//   }
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentMatchTeamData, type TournamentMatchListAPIModel } from "../@types/match";
import { type BackendModels } from "../@types/models";

export class TournamentMatchListAPIModelMethods {
  data: ClientModel<TournamentMatchListAPIModel>;
  _opponentMatchTeamId: BackendModels.ID;

  constructor(data: ClientModel<TournamentMatchListAPIModel>) {
    this.data = data;
    const oppoMatchTeamId = Object.keys(this.data.tournamentMatchTeams).find(
      (matchTeamId) => matchTeamId !== this.currentUserMatchTeamId?.toString(),
    );
    if (!oppoMatchTeamId) console.error("Broken server response. Opponent team not found.");
    this._opponentMatchTeamId = Number(oppoMatchTeamId);
  }

  get currentUserMatchTeamId(): BackendModels.ID {
    return this.data.currentUserMatchTeam;
  }

  get opponentMatchTeamId(): BackendModels.ID {
    return this._opponentMatchTeamId;
  }

  getCurrentUserTournamentMatchTeam(): ClientModel<TournamentMatchTeamData> {
    return this.data.tournamentMatchTeams[this.currentUserMatchTeamId];
  }

  getOpponentTournamentMatchTeam(): ClientModel<TournamentMatchTeamData> {
    return this.data.tournamentMatchTeams[this.opponentMatchTeamId];
  }

  getResultsSender(): ClientModel<TournamentMatchTeamData> | null {
    if (this.getCurrentUserTournamentMatchTeam().ownScore)
      return this.getCurrentUserTournamentMatchTeam();
    if (this.getOpponentTournamentMatchTeam().ownScore)
      return this.getOpponentTournamentMatchTeam();
    return null;
  }

  getMatchScores(): Array<[number?, number?, number?]> {
    const resultSender = this.getResultsSender();
    if (resultSender === null) return [];
    return [
      resultSender.ownScore as [number?, number?, number?],
      resultSender.oppoScore as [number?, number?, number?],
    ];
  }

  isFinished(): boolean {
    return this.getResultsSender() !== null;
  }
}
