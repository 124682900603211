import { type FC } from "react";
import { type TournamentMatchListAPIModel } from "@double-bagel/endpoints/models/@types/match";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import ChallengeColumns from "./challenge";
import RoundRobinColumns from "./round-robin";
import DefaultColumns from "./default";
import DuelColumns from "./duel";

interface ParticipantColumnProps {
  matches?: ClientModel<TournamentMatchListAPIModel>[];
  maxCount?: number;
  withLabels?: boolean;
  withPlayButton?: boolean;
  tournamentType: ClientModel<TournamentModel>["type"];
}

const TournamentMatchesColumn: FC<ParticipantColumnProps> = ({
  matches,
  withLabels = true,
  withPlayButton = true,
  tournamentType,
}) => {
  return (
    <>
      {tournamentType === "CHALLENGE" && (
        <ChallengeColumns
          matches={matches}
          withLabels={withLabels}
          withPlayButton={withPlayButton}
        />
      )}
      {tournamentType === "ROUND-ROBIN" && (
        <RoundRobinColumns
          matches={matches}
          withLabels={withLabels}
          withPlayButton={withPlayButton}
        />
      )}
      {tournamentType === "DEFAULT" && (
        <DefaultColumns matches={matches} withLabels={withLabels} withPlayButton={withPlayButton} />
      )}
      {tournamentType === "DUEL" && (
        <DuelColumns matches={matches} withLabels={withLabels} withPlayButton={withPlayButton} />
      )}
    </>
  );
};

export default TournamentMatchesColumn;
