import { type FC } from "react";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import LoadingColumns from "./loading-card-template";

interface ParticipantColumnProps {
  maxCount?: number;
  withLabels?: boolean;
  withPlayButton?: boolean;
  tournamentType: ClientModel<TournamentModel>["type"];
}

const TournamentMatchesColumnLoading: FC<ParticipantColumnProps> = ({
  withLabels = true,
  withPlayButton = true,
  tournamentType,
}) => {
  return (
    <>
      {tournamentType === "CHALLENGE" && (
        <LoadingColumns
          withLabels={withLabels}
          withPlayButton={withPlayButton}
          matchesCount={6}
          maxCount={6}
        />
      )}
      {tournamentType === "ROUND-ROBIN" && (
        <LoadingColumns
          withLabels={withLabels}
          withPlayButton={withPlayButton}
          matchesCount={3}
          maxCount={3}
        />
      )}
      {tournamentType === "DEFAULT" && (
        <LoadingColumns
          withLabels={withLabels}
          withPlayButton={withPlayButton}
          matchesCount={1}
          maxCount={1}
        />
      )}
      {tournamentType === "DUEL" && (
        <LoadingColumns
          withLabels={withLabels}
          withPlayButton={withPlayButton}
          matchesCount={1}
          maxCount={1}
        />
      )}
    </>
  );
};

export default TournamentMatchesColumnLoading;
