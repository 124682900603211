import { type BackendModels } from "@double-bagel/endpoints/models/@types/models";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";

const useMatchCardDeadline = (matchId: BackendModels.ID, deadline: string): any => {
  const [isFinished, setIsFinished] = useState<boolean>();
  const [deadlineType, setDeadlineType] = useState<"duration" | "date">("date");
  const [duration, setDuration] = useState<moment.Duration>();
  useEffect(() => {
    if (deadline && typeof deadline === "string") {
      const loadableTimeStamp = deadline;
      const momentDuration = moment.duration(moment(loadableTimeStamp).diff(moment.now()));
      setDuration(momentDuration);
    }
  }, [deadline]);
  useEffect(() => {
    if (duration && duration.asMonths() > 1) {
      setDeadlineType("date");
      setIsFinished(false);
    } else if (duration && duration.asMonths() <= 1) {
      setDeadlineType("duration");
      setIsFinished(false);
    }
  }, [duration]);
  useEffect(() => {
    if (duration && duration.asSeconds() < 0) {
      setIsFinished(true);
    }
  }, [duration]);
  const valueWithTimer = useDeadlineWithTimer(matchId, duration, deadlineType !== "duration");
  const valueSimple = useDeadlineDate(deadline, deadlineType !== "date");
  return { isFinished, deadlineType, value: valueWithTimer ?? valueSimple };
};
export default useMatchCardDeadline;

const useDeadlineWithTimer = (
  matchId?: number,
  duration?: moment.Duration,
  disabled: boolean = false,
): string | undefined => {
  const [value, setValue] = useState<string>();
  const ref = useRef<{
    timer: number;
  }>({
    timer: 0,
  });
  const onTickCallback = useCallback(() => {
    if (duration) {
      setValue(`${duration.asDays().toFixed()}d : ${duration.hours()}h : ${duration.minutes()}m`);
    }
  }, [duration]);
  useEffect(() => {
    if (duration && !disabled) {
      setValue(`${duration.asDays().toFixed()}d : ${duration.hours()}h : ${duration.minutes()}m`);
    }
  }, [disabled]);
  useEffect(() => {
    if (disabled) {
      return;
    }
    if (matchId) {
      const timerDescriptor: number = (moment.duration(5, "seconds") as any).timer(
        { start: true, loop: true, wait: 1, executeAfterWait: true },
        function () {
          onTickCallback();
        },
      );
      ref.current = {
        timer: timerDescriptor,
      };
      return () => {
        clearTimeout(ref.current?.timer);
      };
    }
  }, [matchId]);
  return value;
};

export const useDeadlineDate = (
  deadline?: string,
  disabled: boolean = false,
): string | undefined => {
  if (disabled) {
    return;
  }
  return moment(deadline, moment.ISO_8601)?.format("D MMM, Y");
};
