import { type ClientModel } from "../../adapter/client-models";
import { type BackendModels } from "./models";
import { type TournamentGenders, type TournamentLevel } from "./tournament";
import { type HeightUnit } from "./unit";

export const UserGenders = { WOMAN: "WOMAN", MAN: "MAN", DIVERSE: "DIVERSE" } as const;
export const DominantHand = { LEFT: "LEFT", RIGHT: "RIGHT" } as const;
export const FavoriteStrategy = {
  BASELINER: "BASELINER",
  COUNTER_PUNCHER: "COUNTER_PUNCHER",
  SERVE_AND_VOLLEYER: "SERVE_AND_VOLLEYER",
  MOONBALLER: "MOONBALLER",
} as const;
export interface UserModel extends BackendModels.BaseModel {
  phone?: string;
  profile: UserProfile;
}

export interface UserProfile extends BackendModels.BaseModel {
  email?: string;
  avatar?: ClientModel<UserAvatar>;
  name?: string;
  level?: keyof typeof TournamentLevel;
  strategy?: keyof typeof FavoriteStrategy;
  gender?: keyof typeof TournamentGenders;
  hand?: keyof typeof DominantHand;
  city?: string;
  country?: string;
  photo?: string;
  height?: number;
  racket_brand?: ClientModel<BackendModels.RacketBrand>;
  is_empty_profile?: boolean;
  first_name?: string;
  datetime_joined?: string;
  bio?: string;
  solo_team_id?: BackendModels.ID;
  phone?: string;
  user: BackendModels.ID;
}

export interface ShortProfileSerializer extends BackendModels.BaseModel {
  first_name: string;
  user: BackendModels.ID;
  avatar: string;
  phone: string;
  hand: string;
  height: number;
  strategy: string;
  racket_brand: ClientModel<BackendModels.RacketBrand>;
}

export interface NotificationsSettings extends BackendModels.BaseModel {
  notifications_by_email?: boolean;
  email_congratulations?: boolean;
  email_game_reminders?: boolean;
  email_leaderboard_updated?: boolean;
  email_promos?: boolean;
  email_score_set_reminder?: boolean;
  notifications_by_sms?: boolean;
  sms_congratulations?: boolean;
  sms_game_reminders?: boolean;
  sms_leaderboard_updated?: boolean;
  sms_promos?: boolean;
  sms_score_set_reminder?: boolean;
}
export interface UserSettings extends BackendModels.BaseModel {
  level?: keyof typeof TournamentLevel;
  gender?: keyof typeof TournamentGenders;
  city: string;
  country?: string;
  photo?: string;
  height?: number;
  is_empty_settings?: boolean;
  unit_of_len: HeightUnit;
  notifications?: Partial<ClientModel<NotificationsSettings>>;
}

export interface UserAvatar extends BackendModels.BaseModel {
  is_default: boolean;
  image: string;
  avatar: ClientModel<UserAvatar>;
}
