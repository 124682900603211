import { type FC, useEffect, useContext, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import AuthorizedLayout from "@double-bagel/components/layouts/AuthorizedLayout";
import { ProfileContext } from "@double-bagel/context/profile";
import TournamentsCollection from "@double-bagel/components/collections/tournaments";
import { useGetPerfectTournament } from "@double-bagel/endpoints/endpoints.tournament";
import DefaultHeadline from "@double-bagel/components/headlines/default";
import ActualTournamentMatchesSlider from "@double-bagel/components/sliders/tournament/actual-matches";
import JoinMatchBanner from "@double-bagel/components/banners/join-match";
import IndexPageSlider from "@double-bagel/components/sliders/tournament/info";

const IndexPage: FC = () => {
  const { profile } = useContext(ProfileContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    value: perfectTournament,
    execute: fetchPerfectTournament,
    status: fetchPerfectTournamentStatus,
  } = useGetPerfectTournament();
  useEffect(() => {
    void fetchPerfectTournament();
  }, []);
  const [myMatchesCount, setMyMatchesCount] = useState(0);
  return (
    <AuthorizedLayout
      title=""
      image={profile?.avatar?.image ?? ""}
      page="/"
      isLoading={fetchPerfectTournamentStatus === "pending"}
      isShowNotificationsButton={true}
    >
      <JoinMatchBanner />
      <div className={classNames("pt-10 transition-all duration-1000 will-change-contents")}>
        {myMatchesCount > 0 && (
          <DefaultHeadline className="mt-1 pb-3 text-lg tb:pb-[16px] tb:text-2xl dk:pb-6 dk:text-3xl">
            My Matches
          </DefaultHeadline>
        )}

        <ActualTournamentMatchesSlider
          className="mx-[-15px] px-[15px]"
          type="CHALLENGE"
          onTournamentsFetch={(tournaments) => {
            setMyMatchesCount((prev) => prev + tournaments.length);
          }}
        />
        <ActualTournamentMatchesSlider
          className="mx-[-15px] px-[15px]"
          type="ROUND-ROBIN"
          onTournamentsFetch={(tournaments) => {
            setMyMatchesCount((prev) => prev + tournaments.length);
          }}
        />
        <ActualTournamentMatchesSlider
          className="mx-[-15px] px-[15px]"
          type="DUEL"
          onTournamentsFetch={(tournaments) => {
            setMyMatchesCount((prev) => prev + tournaments.length);
          }}
        />
        <ActualTournamentMatchesSlider
          className="mx-[-15px] mb-[32px] px-[15px]"
          type="DEFAULT"
          onTournamentsFetch={(tournaments) => {
            setMyMatchesCount((prev) => prev + tournaments.length);
          }}
        />
      </div>
      {perfectTournament && (
        <>
          <div className="flex justify-between pb-3 tb:pb-[16px] tb:pt-10 dk:pb-6 dk:pt-[64px]">
            <DefaultHeadline className="dk:text-3x text-lg tb:text-2xl">
              Join the tournament
            </DefaultHeadline>
            <Link to="/explore">
              <div className="font-GolosTextfont-normal flex cursor-pointer p-[3px] leading-[20px] text-[#868686] underline underline-offset-4 xs:text-sm tb:text-sm dk:text-[24px] dk:leading-9">
                view all
              </div>
            </Link>
          </div>
          <TournamentsCollection
            tournaments={[perfectTournament?.[0] ?? []]}
            isFetching={fetchPerfectTournamentStatus === "pending"}
          />
        </>
      )}
      <div className="flex justify-between pb-3 pt-3 tb:pb-[16px] tb:pt-[16px] dk:pb-6 dk:pt-6">
        <DefaultHeadline className="dk:text-3x text-lg tb:text-2xl">How it works</DefaultHeadline>
        <Link to="https://help.dbagel.co/en/articles/8856981-double-bagel-rules-and-code-of-conduct">
          <div className="font-GolosTextfont-normal flex cursor-pointer p-[3px] leading-[20px] text-[#868686] underline underline-offset-4 xs:text-sm tb:text-sm dk:text-[24px] dk:leading-9">
            all rules
          </div>
        </Link>
      </div>
      <IndexPageSlider className="mx-[-16px] px-[16px]" />
    </AuthorizedLayout>
  );
};

export default IndexPage;
