import { type PromocodeModel } from "@double-bagel/endpoints/models/@types/promocode";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type AsHookWrappedFunc } from "@double-bagel/endpoints/adapter/hook";
import { useAcceptPromocode } from "@double-bagel/endpoints/endpoints.promocode";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const promocodeRegexp = /^([A-Za-z0-9]{6})$/;
const isVerified = (promocode: string): boolean => promocodeRegexp.test(promocode);
type UsePromocodeResult = {
  fromQP?: string;
  acceptedPromocode: ClientModel<PromocodeModel> | undefined;
  acceptPromocode: (promocode: string) => Promise<AsHookWrappedFunc<PromocodeModel>>;
};
const PROMOCODE_QUERY_PARAM = "promo";
const REDIRECT_ROUTES_BY_AUTH_STATE = {
  UNAUTHORIZED: "/signin/",
  IN_PGOGRESS: "/stage/1/",
  COMPLEATED: "/settings/promocodes/",
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const usePromocode = (
  authState?: {
    isEmptyProfile: boolean;
    isEmptySettings: boolean;
  },
  withRedirect: boolean = false,
): UsePromocodeResult => {
  const [searchParams] = useSearchParams();
  const promocode = searchParams.get(PROMOCODE_QUERY_PARAM);
  const { execute: acceptPromocode, value: acceptedPromocode } = useAcceptPromocode();
  const validatedPromocode = promocode && isVerified(promocode) ? promocode : undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavStart, setIsNavStart] = useState(false);
  useEffect(() => {
    if (!validatedPromocode || !withRedirect || isNavStart) {
      return;
    }
    setIsNavStart(true);
    if (authState === undefined) {
      if (!location.pathname.includes(REDIRECT_ROUTES_BY_AUTH_STATE.UNAUTHORIZED)) {
        navigate(`${REDIRECT_ROUTES_BY_AUTH_STATE.UNAUTHORIZED}?promo=${validatedPromocode}`);
      }
    } else if (authState?.isEmptyProfile && authState?.isEmptySettings) {
      if (!location.pathname.includes(REDIRECT_ROUTES_BY_AUTH_STATE.IN_PGOGRESS)) {
        navigate(`${REDIRECT_ROUTES_BY_AUTH_STATE.IN_PGOGRESS}?promo=${validatedPromocode}`);
      }
    } else if (authState && !authState.isEmptyProfile && !authState.isEmptySettings) {
      if (!location.pathname.includes(REDIRECT_ROUTES_BY_AUTH_STATE.COMPLEATED)) {
        navigate(`${REDIRECT_ROUTES_BY_AUTH_STATE.COMPLEATED}?promo=${validatedPromocode}`);
      }
    }
    setIsNavStart(false);
  }, [validatedPromocode, authState, isNavStart]);
  useEffect(() => {
    if (location.key) {
      if (isNavStart) setIsNavStart(false);
    }
  }, [location]);
  return {
    fromQP: validatedPromocode,
    acceptedPromocode,
    acceptPromocode,
  };
};

export default usePromocode;
