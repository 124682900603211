import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import RaitingCard, { AssessmentPoints } from "@double-bagel/components/cards/raiting-card";
import DefaultHeadline from "@double-bagel/components/headlines/default";
import DateTimePicker from "@double-bagel/components/input/datetime-picker";
import {
  FeedbackScoreType,
  type UserFeedbackScoreModel,
} from "@double-bagel/endpoints/models/@types/match-feedback";
import {
  useSendMatchFeedback,
  useGetMatchTeamInfoById,
  useSendMatchSurvey,
} from "@double-bagel/endpoints/endpoint.match";
import { type FC, useState, useEffect } from "react";
import CentralizedModal from "@double-bagel/components/modal/centralized";
import Image from "@double-bagel/components/image";
import ImageSource from "@double-bagel/assets/Icons/bagels/bagel-question.svg";
import DefaultModal from "../../default";
import useAsync from "@double-bagel/hooks/useAsync";
import { type AsHookWrappedFunc } from "@double-bagel/endpoints/adapter/hook";
import classNames from "classnames";
import { type BackendModels } from "@double-bagel/endpoints/models/@types/models";
import moment from "moment";

type SubmitOpponentRateModalProps = {
  showModal: boolean;
  onClose: () => void;
  matchTeamId: BackendModels.ID;
};

const SubmitOpponentRateModal: FC<SubmitOpponentRateModalProps> = ({
  showModal,
  onClose,
  matchTeamId,
}) => {
  const [feedbackScores, setFeedbackScores] = useState<{
    skills?: number;
    styles?: number;
    smiles?: number;
  }>({
    styles: undefined,
    smiles: undefined,
    skills: undefined,
  });
  const {
    execute: fetchMatchTeamInfo,
    value: matchTeamInfo,
    isFetching: isTeamInfoFetching,
  } = useGetMatchTeamInfoById();
  const { execute: createFeedback, isFetching: isCreateFeedbackFetching } = useSendMatchFeedback();
  const { execute: createSurvey } = useSendMatchSurvey();
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(new Date());
  const [placeOfGame, setPlaceOfGame] = useState("");
  useEffect(() => {
    if (!showModal) return;
    if (matchTeamId) {
      void fetchMatchTeamInfo(Number(matchTeamId));
    }
  }, [matchTeamId, showModal]);
  useEffect(() => {
    if (matchTeamInfo?.currentUserFeedback) {
      setFeedbackScores((prev) => {
        const styles = matchTeamInfo.currentUserFeedback.find((item) => item.type === "STYLES");
        const smiles = matchTeamInfo.currentUserFeedback.find((item) => item.type === "SMILES");
        const skills = matchTeamInfo.currentUserFeedback.find((item) => item.type === "SKILLS");
        return {
          styles: styles?.value ?? prev.styles,
          smiles: smiles?.value ?? prev.smiles,
          skills: skills?.value ?? prev.skills,
        };
      });
    }
    if (matchTeamInfo?.currentUserSurvey) {
      if (matchTeamInfo?.currentUserSurvey?.place)
        setPlaceOfGame(matchTeamInfo.currentUserSurvey.place);
      if (matchTeamInfo?.currentUserSurvey?.dateOfMatch)
        setSelectedDateTime(
          moment(matchTeamInfo?.currentUserSurvey?.dateOfMatch, moment.ISO_8601).toDate(),
        );
    }
  }, [matchTeamInfo]);
  const [isWhyModalShow, setIsWhyModalShow] = useState(false);
  const { execute: rateOpponent, value: rateOpponentResult } = useAsync(
    async (): Promise<
      | [
          AsHookWrappedFunc<UserFeedbackScoreModel>,
          AsHookWrappedFunc<UserFeedbackScoreModel>,
          AsHookWrappedFunc<UserFeedbackScoreModel>,
        ]
      | undefined
    > => {
      if (!isCreateFeedbackFetching && matchTeamInfo) {
        const dateOfMatchMoment = moment(selectedDateTime, moment.ISO_8601);
        const res = await createSurvey(matchTeamInfo?.tournamentMatch?.tournament, {
          place: placeOfGame,
          dateOfMatch: dateOfMatchMoment.toISOString(),
          tournamentMatchTeam: matchTeamId,
        });
        if (res.error) {
          console.error(res.error);
          return;
        }
        return await Promise.all([
          createFeedback(matchTeamInfo.tournamentMatch.tournament, {
            value: feedbackScores.skills,
            matchTeam: Number(matchTeamId),
            type: FeedbackScoreType.SKILLS,
          }),
          createFeedback(matchTeamInfo.tournamentMatch.tournament, {
            value: feedbackScores.smiles,
            matchTeam: Number(matchTeamId),
            type: FeedbackScoreType.SMILES,
          }),
          createFeedback(matchTeamInfo.tournamentMatch.tournament, {
            value: feedbackScores.styles,
            matchTeam: Number(matchTeamId),
            type: FeedbackScoreType.STYLES,
          }),
        ]);
      }
    },
  );
  useEffect(() => {
    if (!rateOpponentResult) return;
    const [skillsResult, stylesResult, smilesResult] = rateOpponentResult;
    if (!skillsResult?.error && !stylesResult?.error && !smilesResult?.error) {
      onClose();
    }
  }, [rateOpponentResult]);

  const isSaveResultButtonDisabled =
    (matchTeamInfo?.currentUserFeedback.length && matchTeamInfo.currentUserFeedback.length > 0) ??
    isCreateFeedbackFetching;
  return (
    <DefaultModal
      showModal={showModal}
      onClose={onClose}
      fullSize
      widthExtraSpaceBottom
      title={"Rate your opponent"}
    >
      <div
        className={classNames(
          "max-h-[90svh] overflow-y-auto px-4 py-5 transition-opacity tb:px-6 tb:py-7 dk:px-8 dk:py-9",
          {
            "opacity-0": isTeamInfoFetching,
          },
        )}
      >
        <DefaultHeadline className="flex justify-between text-base tb:text-2xl dk:text-3xl">
          Rate your opponent
          <div
            onClick={() => {
              setIsWhyModalShow(true);
            }}
            className="flex cursor-pointer p-[3px] font-GolosText text-sm font-normal leading-[20px] text-[#868686] underline underline-offset-4 tb:text-base dk:text-[24px] dk:leading-9"
          >
            Why?
          </div>
        </DefaultHeadline>
        <div className="title1-description pb-[25px] pt-1 text-sm tb:text-lg dk:text-2xl">
          {"We won't share your opinion with them"}
        </div>
        <RaitingCard
          title={AssessmentPoints.SKILLS}
          left={{
            label: "Poor",
          }}
          right={{
            label: "Pro-Tour",
          }}
          type="input"
          value={feedbackScores.skills}
          onChange={([value]) => {
            setFeedbackScores((prevState) => ({ ...prevState, skills: value }));
          }}
          bgColor="#fafafa"
        />
        <div className="pb-[33px] pt-1 text-center text-sm leading-[17px] text-[#868686] tb:pb-[30px] tb:pt-4 tb:text-lg dk:pb-10 dk:pt-6 dk:text-2xl">
          How much of a challenge were they for you?
        </div>
        <RaitingCard
          title={AssessmentPoints.STYLES}
          left={{
            label: "Basic",
          }}
          right={{
            label: "Swagger",
          }}
          type="input"
          value={feedbackScores.styles}
          onChange={([value]) => {
            setFeedbackScores((prevState) => ({ ...prevState, styles: value }));
          }}
          bgColor="#fafafa"
        />
        <div className="pb-[33px] pt-1 text-center text-sm leading-[17px] text-[#868686] tb:pb-[30px] tb:pt-4 tb:text-lg dk:pb-10 dk:pt-6 dk:text-2xl">
          How much swagger do they bring to the court?
        </div>
        <RaitingCard
          title={AssessmentPoints.SMILES}
          left={{
            label: "Meh",
          }}
          right={{
            label: "Chill",
          }}
          type="input"
          value={feedbackScores.smiles}
          onChange={([value]) => {
            setFeedbackScores((prevState) => ({ ...prevState, smiles: value }));
          }}
          bgColor="#fafafa"
        />
        <div className="pt-2 text-center text-[14px] leading-[17px] text-[#868686] tb:pb-[30px] tb:pt-4 tb:text-[18px] dk:pb-10 dk:pt-[24px] dk:text-[24px]">
          Friendly vs. grumpy
        </div>
        <DefaultHeadline className="pt-[32px] text-base tb:text-2xl dk:text-3xl">
          Where did you play?
        </DefaultHeadline>
        <div className="title1-description pt-[4px] text-sm tb:text-lg dk:text-2xl">
          It helps us to build a better tennis community for everyone
        </div>
        <input
          className="mt-[12px] w-full rounded-[10px] bg-[#fafafa] p-[16px] text-base leading-[20px] text-[#BFBFBF] placeholder:text-[#BFBFBF] tb:text-xl dk:text-3xl"
          placeholder="Court name, surface etc."
          value={placeOfGame}
          onChange={(e) => {
            setPlaceOfGame(e.target.value);
          }}
        />
        <DefaultHeadline className="pt-[32px] text-base tb:text-2xl dk:text-3xl">
          And when?
        </DefaultHeadline>
        <DateTimePicker
          className="sticky mt-[12px] w-full rounded-[10px] text-base leading-[20px] text-[#BFBFBF] tb:text-xl dk:text-3xl"
          onChange={({ date }) => {
            if (date) setSelectedDateTime(date as Date);
          }}
          value={selectedDateTime}
          inputBgColor="#fafafa"
        />

        <div className="my-[50px] flex w-full flex-col justify-stretch">
          <Button
            style={isSaveResultButtonDisabled ? ButtonStyle.Disabled : ButtonStyle.Primary}
            id="send_scores"
            isDisabled={!!isSaveResultButtonDisabled}
            onClick={() => {
              void rateOpponent();
            }}
          >
            Save the results
          </Button>
        </div>
        <CentralizedModal showModal={isWhyModalShow}>
          <Image
            src={ImageSource}
            className="mb-4 h-[106px] tb:mb-5 tb:h-[130px] dk:mb-6 dk:h-[180px]"
            alt="bagel-cartoon"
          />
          <div className="pb-2 text-base font-bold leading-6 tb:pb-3 tb:text-xl tb:leading-8 dk:pb-4 dk:text-3xl dk:leading-9">
            {"What’s My Community Score?"}
          </div>
          <div className=" text-center">
            Post-match, both players rate each other on three essential aspects of their tennis
            experience:
            <div className="pt-6">
              <strong>Style</strong> is your tennis swagger, the uniqueness you bring to the game.
            </div>
            <div className="pt-6">
              <strong>Smiles</strong> captures how friendly and pleasant you are as an opponent.{" "}
            </div>
            <div className="pt-6">
              <strong>Skills</strong> reflects your technical and strategic tennis abilities.
            </div>
            <div className="pt-6">
              The average of all these ratings from all your matches forms each respective score.
            </div>
          </div>
          <div className="mt-3 flex w-full flex-col gap-3 tb:mt-4 tb:gap-4  dk:mt-6  dk:gap-6">
            <Button
              style={ButtonStyle.Primary}
              onClick={() => {
                setIsWhyModalShow(false);
              }}
            >
              Got it!
            </Button>
          </div>
        </CentralizedModal>
      </div>
    </DefaultModal>
  );
};

export default SubmitOpponentRateModal;
