import { useState, type FC } from "react";
import {
  type TournamentMatchListAPIModel,
  type TournamentMatchModel,
} from "@double-bagel/endpoints/models/@types/match";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import PlayerAvatar from "@double-bagel/components/avatar/default";
import useAuth from "@double-bagel/hooks/useAuth";
import { Button } from "@double-bagel/components/buttons";
import { ButtonStyle, ButtonFontStyle } from "@double-bagel/components/buttons/default-button";
import MatchResultsScores from "@double-bagel/components/cards/match-results-scores";
import MatchReportModalsController from "@double-bagel/components/modal/compositions/match-report/match-report-controller";
import { TournamentMatchListAPIModelMethods } from "@double-bagel/endpoints/models/methods/match";

interface DuelColumnsProps {
  matches?: ClientModel<TournamentMatchListAPIModel>[];
  maxCount?: number;
  withLabels?: boolean;
  withPlayButton?: boolean;
}

const DuelColumns: FC<DuelColumnsProps> = ({
  matches,
  withLabels = true,
  withPlayButton = true,
}) => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const tournamentId = matches?.[0]?.tournament;
  const [isMatchReportModalsOpened, setIsMatchReportModalsOpened] = useState(false);
  const [tournamentMatchTeamId, setTournamentMatchTeamId] =
    useState<ClientModel<TournamentMatchModel>["id"]>();
  const wrappedMatches = matches?.map((item) => new TournamentMatchListAPIModelMethods(item));
  return (
    <>
      <div className="w-full bg-background">
        {wrappedMatches?.map((item: TournamentMatchListAPIModelMethods, index: number) => (
          <>
            <div key={item.data.id} className="pt-5 tb:pt-6 dk:pt-7">
              {withLabels && (
                <Link to={`/tournament/${tournamentId}/match/${item.data.id}`}>
                  <h4 className="flex w-fit font-Unbounded text-lg tb:text-xl dk:text-3xl">
                    <p className="effect-underline relative">Match {index + 1}</p>
                  </h4>
                </Link>
              )}
              <div
                className={classNames(
                  "grid w-full cursor-pointer grid-cols-[70%_30%] pt-2 tb:pt-3 dk:pt-4",
                )}
                onClick={() => {
                  navigate(`/tournament/${tournamentId}/match/${item.data.id}`);
                }}
              >
                <div className="row-[2/2] m-[1px] rounded-bl-xl bg-white p-3 tb:p-5 dk:p-7">
                  <div
                    className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6"
                    key={item.data.id}
                  >
                    <Link
                      to={`/user/${item.getOpponentTournamentMatchTeam()?.members?.[0]?.userId}`}
                      className="contents"
                    >
                      <PlayerAvatar
                        highlighted={
                          item.getOpponentTournamentMatchTeam()?.members?.[0]?.userId.toString() ===
                          authState?.userId
                        }
                        image={item.getOpponentTournamentMatchTeam()?.members?.[0]?.avatar ?? ""}
                        classNameWrapper="dk:w-12 dk:h-12 tb:w-8 tb:h-8 w-6 h-6"
                        className="h-6 w-6 bg-clip-content p-[2px] tb:h-8 tb:w-8 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                      />
                      <span
                        className={classNames(
                          "text-base leading-6 text-fonts-accentColor tb:text-2xl dk:text-3xl",
                          {
                            "text-fonts-accentColor":
                              item
                                .getOpponentTournamentMatchTeam()
                                ?.members?.[0]?.userId.toString() === authState?.userId,
                            "text-fonts-darken":
                              item
                                .getOpponentTournamentMatchTeam()
                                ?.members?.[0]?.userId.toString() !== authState?.userId,
                            "font-semibold":
                              item?.data.winner === item.getOpponentTournamentMatchTeam()?.teamId,
                          },
                        )}
                      >
                        {item.getOpponentTournamentMatchTeam()?.members?.[0]?.userId.toString() ===
                        authState?.userId
                          ? "Me"
                          : item.getOpponentTournamentMatchTeam()?.members?.[0]?.name ??
                            `Opponent ${item.getOpponentTournamentMatchTeam()?.members?.[0]?.userId}`}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="row-[1/2] m-[1px] rounded-tl-xl bg-white p-3 tb:p-5 dk:p-7">
                  <div
                    className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6"
                    key={item.data.id}
                  >
                    <Link
                      to={`/user/${item.getCurrentUserTournamentMatchTeam()?.members?.[0]?.userId}`}
                      className="contents"
                    >
                      <PlayerAvatar
                        highlighted={
                          item
                            .getCurrentUserTournamentMatchTeam()
                            ?.members?.[0]?.userId?.toString() === authState?.userId
                        }
                        image={item.getCurrentUserTournamentMatchTeam()?.members[0]?.avatar}
                        classNameWrapper="dk:w-12 dk:h-12 tb:w-8 tb:h-8 w-6 h-6"
                        className="h-6 w-6 bg-clip-content p-[2px] tb:h-8 tb:w-8 tb:p-[3px] dk:h-12 dk:w-12 dk:p-1"
                      />
                      <span
                        className={classNames(
                          "text-base leading-6 text-fonts-accentColor tb:text-2xl dk:text-3xl",
                          {
                            "text-fonts-accentColor":
                              item
                                .getCurrentUserTournamentMatchTeam()
                                ?.members[0]?.userId?.toString() === authState?.userId,
                            "text-fonts-darken":
                              item
                                .getCurrentUserTournamentMatchTeam()
                                ?.members[0]?.userId?.toString() !== authState?.userId,
                            "font-semibold":
                              item?.data.winner ===
                              item.getCurrentUserTournamentMatchTeam()?.teamId,
                          },
                        )}
                      >
                        {item
                          .getCurrentUserTournamentMatchTeam()
                          ?.members[0]?.userId?.toString() === authState?.userId
                          ? "Me"
                          : item.getCurrentUserTournamentMatchTeam()?.members[0]?.name ??
                            `Opponent ${item.getCurrentUserTournamentMatchTeam()?.members[0]?.userId}`}
                      </span>
                    </Link>
                  </div>
                </div>
                <div
                  className={classNames("row-[1/3] m-[1px] flex rounded-r-xl bg-white", {
                    "justify-center": !item.data.winner,
                  })}
                >
                  {item.data.winner && (
                    <MatchResultsScores
                      team1={item.getCurrentUserTournamentMatchTeam().teamId}
                      team2={item.getOpponentTournamentMatchTeam().teamId}
                      scores={item.getMatchScores()}
                      className="w-full"
                      winnerTeamId={item.data.winner.toString()}
                    />
                  )}
                  {!item.data.winner && withPlayButton && (
                    <Button
                      style={ButtonStyle.Primary}
                      buttonFontClass={ButtonFontStyle.Unbounded}
                      onClick={() => {
                        navigate(`/tournament/${tournamentId}/match/${item.data.id}/`);
                      }}
                      className="self-center rounded-xl px-3 transition-transform hover:scale-105"
                    >
                      PLAY
                    </Button>
                  )}
                  {!item.data.winner && !withPlayButton && (
                    <div className="grid grid-cols-3 grid-rows-2 gap-4 px-3 py-3 text-4xl text-fonts-optional opacity-70 tb:gap-6 tb:px-3 tb:py-5 tb:text-5xl dk:gap-6 dk:px-5 dk:py-7 dk:text-6xl">
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!item?.data.currentUserFeedback && item.data.winner && (
              <>
                <div className="grid grid-flow-col content-center gap-3 pt-2 tb:grid-flow-col tb:gap-4 tb:pt-3 dk:grid-flow-col dk:gap-6 dk:pt-4">
                  <Button
                    style={ButtonStyle.Secondary}
                    onClick={() => {
                      setIsMatchReportModalsOpened(true);
                      setTournamentMatchTeamId(item.data.currentUserMatchTeam);
                    }}
                  >
                    Rate opponent
                  </Button>
                </div>
              </>
            )}
          </>
        ))}
      </div>
      {tournamentId && tournamentMatchTeamId && (
        <MatchReportModalsController
          tournamentId={tournamentId}
          matchTeamId={tournamentMatchTeamId}
          showModal={isMatchReportModalsOpened}
          onClose={() => {
            setIsMatchReportModalsOpened(false);
          }}
          specificModal={"rate"}
        />
      )}
    </>
  );
};

export default DuelColumns;
